// Bootstrap Required 
@import "node_modules/bootstrap/scss/functions";
@import "variables";
@import "node_modules/bootstrap/scss/mixins";

// Bootstrap Optional
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/magnific-popup/src/css/settings";
@import "node_modules/magnific-popup/src/css/main";

// @include media-breakpoint-down(md){
// 	body *{
// 		display: none !important;
// 	}
// 	body:before{
// 		display: block !important;
// 		content: 'No disponible en dispositivos móviles y tabletas';
// 		position: fixed;
// 		top: 50%;
// 		color: white;
// 		width: 100%;
// 		padding: 50px;
// 		text-align: center;
// 	}
// }

html{
	font-size: 16px;
	@include media-breakpoint-up(lg){
		font-size: 21px;
	}
}

body{
	background-color: black;
	-webkit-font-smoothing: antialiased;
}

h3,.h3{
	line-height: 1.333em;
}

h4,.h4{
	line-height: 1.4em;
}

h6,.h6{
	line-height: 1.428em;
}

img{
	max-width: 100%;
	height: auto;
	display: block;
	position: relative;
}

.-all-small-caps{
	font-variant: all-small-caps;
}

.-mb-1ln{
	margin-bottom: 1.4em;
}

.oldstyle-nums {
    font-variant-numeric: oldstyle-nums;
    font-feature-settings: "onum";
}

.watch-prev-gala{
	display: inline-flex;
	.icon{
		width: 32px;
		height: initial;
		margin-right: 4px;
	}
}

.section{
	min-height: 100vh;
	position: relative;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 50px 0px 100px 0px;
	.sticky-wrapper{
		position: absolute;
		top: 0; left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
	.sticky-element{
		position: relative;
		width: 100%;
		height: 50px;
		left: 0;
		top: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 99;
		font-size: 1rem;
		font-weight: bold;
		text-transform: uppercase;
		cursor: pointer;
		pointer-events: all;
		font-variant: all-small-caps;
		line-height: 1em;
		padding-bottom: 2px;
		opacity: 0;
		transform: translate3d(0,100%,0);
		transition: transform 666ms;
		letter-spacing: 0.1em;
		&:after{
			content: '';
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			border-top: 7px solid black;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

body.initialized{
	.sticky-element{
		opacity: 1;
		transform: translate3d(0,0%,0);
	}
}

.section.-white{
	.sticky-element:after{
		border-top-color: white;
	}
}

.section.-light{
	.sticky-element:after{
		border-top-color: #bfb7b0;
	}
}

.section.-dark{
	.sticky-element:after{
		border-top-color: #333333;
	}
}

.section.-black{
	.sticky-element:after{
		border-top-color: #000000;
	}
}

.section.-white,
.sticky-element.-white{
	color: $primary;
	background-color: white;
}
.section.-light,
.sticky-element.-light{
	color: #ffffff;
	background-color: #bfb7b0;
}
.section.-dark,
.sticky-element.-dark{
	color: $primary;
	background-color: #333333;
}
.section.-black,
.sticky-element.-black{
	color: $primary;
	background-color: black;
}

#section01{
	.logo-ponce{
	}
	.logo-legacy{
		display: block;
		width: 90%;
		margin-left: auto;
		margin-right: auto;
	}
}

#section02{
	@include media-breakpoint-up(lg){
		.txt-intro{
			font-size: 36px;
			line-height: 48px;
		}
	}
}

#sponsors-cloud{
	animation-name: fadeIn;
    animation-duration: 2s;
	img{
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
	}
}

#sponsorship-table{
	text-align: left;
	@include media-breakpoint-down(sm){
		.h3{
			font-size: 1.2rem;
		}
	}
	border-top: solid 1px white;
	.sponsorship-table--section{
		position: relative;
		border-bottom: solid 1px white;
		.sponsorship-table--head{
			padding: 20px 0;
			margin: 0;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
			transition: color 0.2s;
			&:hover{
				color: $primary !important;
			}
		}
		.sponsorship-table--content{
			position: relative;
			border-top: dotted 1px white;
			margin-bottom: -1px;
			height: 0;
			overflow: hidden;
			> button.close{
				width: 26px;
				height: 26px;
				position: absolute;
				top: 26px; right: 0;
				background-color: transparent;
				background-image: url(../images/close.svg);
				background-size: 26px;
				background-position: right;
				background-repeat: no-repeat;
				border: none;
				outline: none;
				cursor: pointer;
				z-index: 9;
			}
			> .wrapper{
				padding: 88px 0 52px 0;
				@include media-breakpoint-up(md){
					padding: 52px 52px 52px 0;
				}
				transform: translate3d(0,-66%, 0);
				opacity: 0;
				transition: all 0.66s cubic-bezier(0,1,0,1);
				ul.list{
					padding-left: 0;
					li{
						color: white;
						padding-left: 0.8em;
    					position: relative;
						&:before{
							content: '·';
							position: absolute;
							left: 0;
							color: $primary;					
						}
					}
				}
			}
		}
		&.active{
			.sponsorship-table--content{
				height: auto;
				> .wrapper{
					transform: translate3d(0,0%, 0);
					opacity: 1;
				}
			}
		}
	}
}

#contact-section{
	padding: 30px 0px 50px 0px;
	ul.social{
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		align-items: center;
		justify-content: center;
		li{
			margin: 0 5px;
			a{
				width: 32px;
				height: 32px;
				display: inline-block;
				border: solid 1px $primary;
				border-radius: 50%;
				line-height: 32px;
				font-size: 16px;
			}
		}
	}
}

[data-js-scroll-in]{
	opacity: 0;
	transition-property: opacity;
	transition-duration: 1000ms;
	&.scrollIn{
		opacity: 1;
	}
}

body.fix-ie{
	.section{
		display: block;
	}
	#section01{
		.logo-ponce{
			margin-bottom: 60px;
		}
	}
	.sticky-element{
		font-size: 18px;
	}
	.watch-prev-gala{
		display: inline-block;
		.icon{
			width: 32px;
			height: initial;
			margin-right: 4px;
		}
	}
}